import invariant from "tiny-invariant";
import type { VerifyFunctionArgs } from "./verify";
import { prisma } from "~/utils/db.server";
import { verifySessionStorage } from "~/utils/verification.server";
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { PasswordSchema } from "~/utils/model-utils/user-validation";
import z from "zod";
import {
  requireAnonymous,
  resetUserPassword,
} from "~/utils/auth-utils/auth.server";
import { getFieldsetConstraint, parse } from "@conform-to/zod";
import { Form, useActionData, useLoaderData } from "@remix-run/react";
import { useIsPending } from "~/utils/misc";
import { conform, useForm } from "@conform-to/react";
import { AuthenticityTokenInput } from "remix-utils/csrf/react";
import { validateCSRF } from "~/utils/csrf.server";
import { Button } from "~/components/ui/Button";
import { Label } from "~/components/ui/Label";
import { Input } from "~/components/ui/Input";
import ErrorList from "~/components/ui/ErrorList";

const resetPasswordEmailSessionKey = "resetPasswordEmail";

export async function handleVerification({
  request,
  submission,
}: VerifyFunctionArgs) {
  invariant(submission.value, "submission.value should be defined by now");
  const target = submission.value.target;
  const user = await prisma.user.findFirst({
    where: { email: target },
    select: { email: true },
  });
  // we don't want to say the user is not found if the email is not found
  // because that would allow an attacker to check if an email is registered
  if (!user) {
    submission.error.code = ["Invalid code"];
    return json({ status: "error", submission } as const, { status: 400 });
  }

  const verifySession = await verifySessionStorage.getSession(
    request.headers.get("cookie"),
  );
  verifySession.set(resetPasswordEmailSessionKey, user.email);
  return redirect("/reset-password", {
    headers: {
      "set-cookie": await verifySessionStorage.commitSession(verifySession),
    },
  });
}

const ResetPasswordSchema = z
  .object({
    password: PasswordSchema,
    confirmPassword: PasswordSchema,
  })
  .refine(({ confirmPassword, password }) => password === confirmPassword, {
    message: "The passwords did not match",
    path: ["confirmPassword"],
  });

async function requireResetPasswordEmail(request: Request) {
  await requireAnonymous(request);
  const verifySession = await verifySessionStorage.getSession(
    request.headers.get("cookie"),
  );
  const resetPasswordEmail = verifySession.get(resetPasswordEmailSessionKey);
  if (typeof resetPasswordEmail !== "string" || !resetPasswordEmail) {
    throw redirect("/login");
  }
  return resetPasswordEmail;
}

export async function loader({ request }: LoaderFunctionArgs) {
  const resetPasswordEmail = await requireResetPasswordEmail(request);
  return json({ resetPasswordEmail });
}

export async function action({ request }: ActionFunctionArgs) {
  const resetPasswordEmail = await requireResetPasswordEmail(request);
  const formData = await request.formData();

  // 🐨 validate the CSRF token
  await validateCSRF(formData, request.headers);

  const submission = parse(formData, {
    schema: ResetPasswordSchema,
  });
  if (submission.intent !== "submit") {
    return json({ status: "idle", submission } as const);
  }
  if (!submission.value?.password) {
    return json({ status: "error", submission } as const, { status: 400 });
  }
  const { password } = submission.value;

  await resetUserPassword({ email: resetPasswordEmail, password });
  const verifySession = await verifySessionStorage.getSession(
    request.headers.get("cookie"),
  );
  return redirect("/login", {
    headers: {
      "set-cookie": await verifySessionStorage.destroySession(verifySession),
    },
  });
}

export const meta: MetaFunction = () => {
  return [{ title: "Reset Password | Ranger Budget" }];
};

export default function ResetPasswordPage() {
  const data = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();
  const isPending = useIsPending();

  const [form, fields] = useForm({
    id: "reset-password",
    constraint: getFieldsetConstraint(ResetPasswordSchema),
    lastSubmission: actionData?.submission,
    onValidate({ formData }) {
      return parse(formData, { schema: ResetPasswordSchema });
    },
    shouldRevalidate: "onBlur",
  });

  return (
    <div className="flex min-h-full flex-col justify-center px-8 pb-32 pt-20">
      <div className="mx-auto flex w-full max-w-md flex-col gap-8 lg:gap-10">
        <div className="flex flex-col gap-3 text-center">
          <p className="text-2xl font-semibold lg:text-4xl">Password Reset</p>
          <p className="text-sm text-muted-foreground md:text-base">
            Hi, {data.resetPasswordEmail}. <br /> No worries. It happens all the
            time.
          </p>
        </div>

        <div className="mx-auto w-full max-w-md">
          <Form
            method="POST"
            {...form.props}
            className="mx-auto flex max-w-sm flex-col gap-4 md:gap-6"
          >
            <AuthenticityTokenInput />
            <div className="space-y-2">
              <Label>New Password</Label>
              <Input type="password" {...conform.input(fields.password)} />
              {fields.password.errors && (
                <div id={`${fields.password.id}-error`}>
                  <ErrorList errors={fields.password.errors} />
                </div>
              )}
            </div>
            <div className="space-y-2">
              <Label>Confirm Password</Label>
              <Input
                type="password"
                {...conform.input(fields.confirmPassword)}
              />
              {fields.confirmPassword.errors && (
                <div id={`${fields.confirmPassword.id}-error`}>
                  <ErrorList errors={fields.confirmPassword.errors} />
                </div>
              )}
            </div>
            <ErrorList errors={form.errors} id={form.errorId} />

            <Button className="w-full" type="submit" disabled={isPending}>
              Reset password
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
